<script setup lang="ts">
import dayjs from 'dayjs'
import { reactive } from 'vue'
import MobileSearchBar from './_components/SearchBar.vue'
import DetailTop from './_components/ListTop.vue'
import bgBrandLogo from '@/assets/images/list/bg_brand_logo.png'
import iconCalender from '@/assets/images/list/icon_filter_calender.png'
import { useFilterConditionsStore } from '@/stores/filter-conditions'
import { E_RANK_TYPE } from '~/enums'
import type { RankListAttrDto, RankTypeDto } from '~/apis/back/types'
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()
const route = useRoute()

useSeoMeta({
  title: t('key.attractions.list.seo.title'),
  description: t('key.attractions.list.seo.description'),
  ogImageUrl,
})

const { clearSearch } = useSearchAttr()
const { tabList, currentTab, handleChangeTab, fetchTabList } = useAttrOrExperTabs()
const { filters } = useExperienceList()

const experienceFilterLen = computed(() => {
  const len1 = filters.value.instantConfirmation ? 1 : 0
  const len2 = filters.value.freeCancellation ? 1 : 0
  return len1 + len2
})

fetchTabList()

enum SelectedDateType {
  CALENDAR = 0,
  TODAY = 1,
  TOMORROW = 2,
}

const formatter = 'DD MMM'
const data = reactive({
  list: {
    items: [] as RankListAttrDto[],
    loading: true,
    loadingMore: false,
    finished: true,
    error: false,
    showErrorBtn: true,
  },
  rank: {
    rankList: [] as RankTypeDto[],
    rankIndex: 0,
  },
  currSelectedDateType: SelectedDateType.CALENDAR, // 0 日历
  isLoading: false,
  isError: false,
})

const filterConditionsStore = useFilterConditionsStore()

function clickCalender() {
  data.currSelectedDateType = SelectedDateType.CALENDAR
}

function clickToday() {
  filterConditionsStore.filterConditions.date = dayjs().valueOf()
  data.currSelectedDateType = SelectedDateType.TODAY
}

function clickTomorrow() {
  filterConditionsStore.filterConditions.date = dayjs().add(1, 'day').valueOf()
  data.currSelectedDateType = SelectedDateType.TOMORROW
}

const filterLength = computed(() => (currentTab.value === E_RANK_TYPE.ATTRACTIONS
  ? filterConditionsStore.filterConditions.filterIdList.length
  : experienceFilterLen.value) || 0)

const isTomorrow = computed(() => data.currSelectedDateType === SelectedDateType.TOMORROW || dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD') === dayjs().add(1, 'day').format('YYYY-MM-DD'))
const isToday = computed(() => data.currSelectedDateType === SelectedDateType.TODAY || dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'))

watch(
  [
    () => filterConditionsStore.filterConditions.selectedCity,
  ],
  () => {
    clearSearch()
    navigateToList({ replace: true })
  },
)

const target = ref<HTMLElement | null>(null)
const targetIsVisible = useElementVisibility(target)
</script>

<template>
  <div class="list">
    <div class="content">
      <DetailTop ref="target"></DetailTop>
      <div
        v-show="targetIsVisible"
        z-10
        :style="{
          backgroundColor: 'var(--tc-color-bg-list)',
        }"
      >
        <div bg="white">
          <div overflow-x="auto">
            <img :src="bgBrandLogo" h="12" class="align-middle" />
          </div>
        </div>
      </div>
      <div v-show="!targetIsVisible" bg="white" sticky top-0 z-10 h-12 w-full flex items-center px-2>
        <MobileSearchBar w-full />
      </div>
      <div class="filter">
        <div flex="~ 1 items-center" p="y-1.5 x-2" class="overflow-x-auto">
          <BusinessBSelectTicket v-if="currentTab !== E_RANK_TYPE.ALL" :page-type="currentTab" m="r-2">
            <div
              v-if="filterLength > 0"
              z="1" h="3" w="3" rounded="full" text="8px white center" leading="3"
              class="absolute right-0 bg-black leading-3 -top-1"
            >
              {{ filterLength }}
            </div>
            <div
              h="8" flex="~ 1 items-center" b="~ 1 solid #777474" rounded="full" bg="#F3F3F3" p="x-4"
              class="line-clamp-1"
            >
              <img w="4" src="~assets/icons/icon-huge.png" />
            </div>
          </BusinessBSelectTicket>
          <MobileSelectDate v-model="filterConditionsStore.filterConditions.date" :formatter="formatter">
            <template #default="{ formatDate }">
              <div
                h="8" mr="2" flex="~ 1 items-center" b="~ 1 solid #777474" rounded="full" bg="#F3F3F3" p="x-4" text="sm"
                class="line-clamp-1"
                :class="{
                  'conditions-high':
                    filterConditionsStore.filterConditions.date && data.currSelectedDateType === SelectedDateType.CALENDAR,
                }"
                @click="clickCalender"
              >
                <p v-if="filterConditionsStore.filterConditions.date">
                  {{ formatDate }}
                </p>
                <img v-else width="21" height="21" :src="iconCalender" />
              </div>
            </template>
          </MobileSelectDate>
          <div
            h="8" m="r-2" flex="~ items-center" b="~ 1 solid #777474" rounded="full" p="x-4" class="line-clamp-1"
            text="sm"
            :style="{ backgroundColor: isToday ? '#f3f3f3' : 'white' }"
            @click="clickToday"
          >
            <p>{{ t('key.attraction.list.today') }}</p>
          </div>
          <div
            h="8" m="r-2" flex="~ items-center" b="~ 1 solid #777474" rounded="full" p="x-4" text="sm"
            class="line-clamp-1"
            :style="{ backgroundColor: isTomorrow ? '#f3f3f3' : 'white' }"
            @click="clickTomorrow"
          >
            <p>{{ t('key.attraction.list.tomorrow') }}</p>
          </div>
        </div>
      </div>
      <div class="tabs shadow-[0_2px_4px_0_#D8D8D840]" sticky top-12 z-2 bg-white>
        <div class="tabs-content">
          <div class="tabs-content-line"></div>
          <div flex="~ items-center" space-x="5" p="x-3" overflow-x="auto">
            <div v-for="(item, index) of tabList" :key="index" flex="~ items-center justify-center" :style="route.query.query ? '' : 'flex: 1'">
              <div
                h="9" flex="~ items-center" class="relative"
                :class="currentTab === item.rankType ? 'tabs-content-item-sub-high b-b-2 b-b-solid b-b-black font-600' : ''"
                @click="handleChangeTab(item.rankType || '')"
              >
                <img v-if="item.rankType === E_RANK_TYPE.ALL" class="h-4 w-4 align-text-top" m="r-1" src="~/assets/icons/select-all.png" />
                <img
                  v-else-if="item.rankType === E_RANK_TYPE.ATTRACTIONS" class="h-4 w-4 align-text-top" m="r-1"
                  src="~/assets/icons/ferris-wheel.png"
                />
                <img v-else w="4" h="4" class="align-text-top" m="r-1" src="~/assets/icons/parachute-box-solid.png" />
                <div
                  v-if="currentTab === item.rankType && data.rank.rankList?.length > 1"
                  class="tabs-content-item-sub-line"
                >
                </div>
                <span text="sm center" whitespace="nowrap" w="full">
                  {{ item.rankTypeName }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileTopAll v-if="currentTab === E_RANK_TYPE.ALL" />
      <MobileTopAttractions v-if="currentTab === E_RANK_TYPE.ATTRACTIONS" />
      <MobileTopExperiences v-if="currentTab === E_RANK_TYPE.EXPERIENCE" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.list {
  width: 100%;
  height: 100%;
  background-color: white;

  .content {
    width: 100%;
    background-color: white;

    position: relative;

    .filter {
      top: 0;
      background-color: white;
      z-index: 100;

      .tabs {
        background-color: white;
        // margin-top: -20px;
        border-radius: 14px 14px 0 0;

        &-content {
          display: flex;
          flex-direction: column;
          // align-items: center;

          &-line {
            // flex: 1;
            width: 100%;
            height: 0.2px;
            background-color: #b7b7b7;
          }

          &-item {
            // margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            overflow-x: scroll;

            // 隐藏滑动条
            &::-webkit-scrollbar {
              display: none;
            }

            &-sub {
              margin: 0 10px;
              height: 48px;

              display: flex;
              text-align: center;

              position: relative;

              >span {
                flex: 1 1 auto;
                line-height: 48px;
                color: #000000;

                font: {
                  size: 14px;
                  weight: 300;
                }

                // 文本不允许换行
                white-space: nowrap;
              }

              &-high {
                >span {
                  font-weight: 700;
                }
              }

              &-line {
                width: 100%;
                height: 2px;
                background-color: #000000;

                position: absolute;
                left: 0;
                // top: 0;
                bottom: 0;
              }
            }
          }
        }
      }
    }

    .list-loading {
      margin-top: 60px;
    }

    .list-items {
      display: flex;
      flex-direction: column;
      padding-top: 4px;

      &-sub {
        margin: 4px 8px;
        box-shadow: 0px 0px 12px 1px rgba(181, 181, 181, 0.25);
      }
    }
  }
}

.search-result {
  margin: 10px 0 20px;

  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
