<template>
  <van-skeleton :loading="loading" animated :style="{ flexDirection: 'column' }">
    <template #template>
      <div v-for="item in 5" :key="item" flex="~" w="full" m="y-2">
        <van-skeleton-image round />
        <div flex="1" m="l-4">
          <van-skeleton-paragraph row-width="60%" />
          <van-skeleton-paragraph />
          <van-skeleton-paragraph />
          <van-skeleton-paragraph />
        </div>
      </div>
    </template>
    <div p="b-2">
      <p m="y-2.5 l-2">
        {{ t('key.attractions.list.search.result', [searchNum || '0', route.query.query as string]) }}
      </p>

      <Empty v-if="!loading && searchNum === 0" desc="" :show-btn="true" @empty-event="fetchSearch" />
      <MobileTopAttractionsItem
        v-for="(item, index) of confirmAttrList[E_RANK_TYPE.ATTRACTIONS]"
        :key="index"
        :top-choice="index === 0"
        :item-index="index"
        :item-data="item"
        :hide-rank-num="true"
        :rank-type="E_RANK_TYPE.ATTRACTIONS"
        class="m-2 rounded-3 shadow-[0_0_12px_1px_rgba(181,181,181,0.25)]"
      />
      <MobileTopExperiencesItem
        v-for="(item, index) of confirmAttrList[E_RANK_TYPE.EXPERIENCE]"
        :key="index"
        class="m-2 rounded-3 shadow-[0_0_12px_1px_rgba(181,181,181,0.25)]"
        :top-choice="!confirmAttrList[E_RANK_TYPE.EXPERIENCE].length && index === 0"
        :hide-rank-num="true"
        :item-index="index"
        :item-data="item"
        :rank-type="E_RANK_TYPE.EXPERIENCE"
      />
    </div>
  </van-skeleton>
</template>

<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

const loading = ref(false)
const { t } = useI18n()
const route = useRoute()
const { confirmAttrList, doAllSearch, searchNum } = useSearchAttr()
const filterConditionsStore = useFilterConditionsStore()

function fetchSearch() {
  loading.value = true
  doAllSearch(route.query.query as string).then((searhResult) => {
    confirmAttrList.value = {
      [E_RANK_TYPE.ATTRACTIONS]: searhResult.rankAttList || [],
      [E_RANK_TYPE.EXPERIENCE]: searhResult.expSearchList || [],
    }
    loading.value = false
  })
}

watch(
  [() => route.query.query, () => filterConditionsStore.filterConditions.date],
  () => {
    if (route.query.query) {
      fetchSearch()
    }
  },
  {
    immediate: true,
  },
)
</script>

<style>

</style>
