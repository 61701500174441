<script setup lang="ts">
import iconRank_1 from '@/assets/images/list/icon_rank_top1.png'
import iconRank_2 from '@/assets/images/list/icon_rank_top2.png'
import iconRank_3 from '@/assets/images/list/icon_rank_top3.png'
import iconRank_4 from '@/assets/images/list/icon_rank_top4.png'

import { useCommonStore } from '@/stores/common'
import { toThousands } from '@/utils'
import type { AttSpecialTopicConfigDTO } from '~/apis/back/types'

const props = defineProps<{
  itemIndex: number
  itemData: AttSpecialTopicConfigDTO
  hideRankNum?: boolean
  topChoice?: boolean
}>()

const { t } = useI18n()
const commonDataStore = useCommonStore()
const priceSymbol = commonDataStore.getCurrencySymbol
const isTop = computed(() => {
  return props.itemIndex < 3
})

const rangImg = computed(() => {
  switch (props.itemIndex) {
    case 0:
      return iconRank_1
    case 1:
      return iconRank_2
    case 2:
      return iconRank_3
    default:
      return iconRank_4
  }
})

const rankStyle = computed(() => {
  return {
    backgroundImage: `url(${rangImg.value})`,
    backgroundSize: '100% 100%',
  }
})

const priceText = computed(() => {
  return priceSymbol + toThousands(props.itemData.minPrice!)
})

const { navigatorToExperienceDetail } = useExperienceList()

function clickItem() {
  navigatorToExperienceDetail(props, !props.hideRankNum)
}
</script>

<template>
  <div>
    <div v-if="topChoice" class="first-card" @click="navigatorToExperienceDetail({ itemData })">
      <h4>{{ t('key.search.list.top.choice') }}</h4>
      <img :src="itemData.cover">
      <div class="card-content">
        <h3 class="attr-name">
          {{ itemData.specialTopicName }}
        </h3>
        <div class="card-detail">
          <div>
            <div v-if="!itemData.minPrice" class="truncate" text="3 black" font="600">
              {{ t('key.attractions.list.see.price') }}
            </div>
            <div text="xs #777474" class="truncate" font="500">
              {{ t('key.list.sites', [itemData.websiteNum]) }}
            </div>
            <h2 v-if="itemData.minPrice">
              {{ priceText }}
            </h2>
            <div v-if="itemData.cashbackDesc" class="truncate" text="2.5" :style="{ color: 'var(--tc-color-cashback-desc)' }">
              {{ itemData.cashbackDesc }}
            </div>
          </div>
          <van-button type="primary" :style="{ height: '34px' }">
            {{ itemData.minPrice ? t('key.list.btnTitle') : t('key.list.getPrice') }}
          </van-button>
        </div>
      </div>
    </div>
    <div v-else class="list-item" h="29" @click.stop="clickItem">
      <div class="item-left">
        <img w="30" h="29" rounded="l-xl" :src="itemData.cover" object="cover" />
        <div v-if="!hideRankNum" class="absolute left-2.5 top-0" :class="isTop ? 'w-10 h-13' : 'w-8 h-10'" :style="rankStyle">
          <p v-if="!isTop" m="t-2" text="sm #683815 center" font="700">
            {{ itemIndex < 9 ? `0${itemIndex + 1}` : itemIndex + 1 }}
          </p>
        </div>
      </div>

      <div class="item-right">
        <div class="item-right-top">
          <p class="line-clamp-2" mt="0.5" text="3.75" font="600" leading="tight" tracking="tighter">
            {{ itemData.specialTopicName }}
          </p>
        </div>

        <div class="item-right-bottom">
          <div class="bottom-left">
            <p v-if="!itemData.minPrice" class="truncate" text="3 black" font="600">
              {{ t('key.attractions.list.see.price') }}
            </p>
            <p v-if="itemData.websiteNum! > 0" class="truncate" text="xs #777474" font="500">
              {{ t('key.list.sites', [itemData.websiteNum]) }}
            </p>
            <p v-if="itemData.minPrice" class="truncate" text="lg black" font="600">
              {{ priceText }}
            </p>
            <p v-if="itemData.cashbackDesc" class="truncate" text="2.5" :style="{ color: 'var(--tc-color-cashback-desc)' }">
              {{ itemData.cashbackDesc }}
            </p>
          </div>
          <div m="b-1">
            <van-button type="primary" size="small" style="min-width: 5.5rem;">
              <span text="sm" p="x-0.25">{{ itemData.minPrice ? t('key.list.btnTitle') : t('key.list.getPrice') }}</span>
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-item {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;

  .item-left {
    position: relative;

    .item-img {
      width: 102px;
      height: 96px;
      object-fit: cover;
      border-radius: 10px;
    }

    .item-rank {
      position: absolute;
      left: 10px;
      top: 0;
      width: 28px;
      height: 32px;

      > p {
        margin-top: 4px;
        width: 28px;
        color: #683815;
        text-align: center;
        font: {
          size: 15px;
          weight: 700;
        }
      }
    }

    .item-rank-top {
      width: 33px;
      height: 38px;
    }
  }

  .item-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px 0;
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;

      .top-rank {
        width: 34px;
        height: 34px;
      }

      .top-title {
        font: {
          size: 13px;
          weight: 600;
        }
        // 限制一行
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-bottom {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex: 1;
      overflow: hidden;

      padding: 20px 0 0 0;
      // background-color: blue;

      .bottom-left {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 2px;
        overflow: hidden;

        // background-color: red;

        > p {
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏溢出内容 */
          text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
        }

        &-sites {
          color: #777474;
          font: {
            size: 12px;
            weight: 600;
          }
        }

        &-price {
          margin-top: 5px;
          color: #000000;
          font: {
            size: 18px;
            weight: 600;
          }
        }

        &-detail {
          white-space: nowrap;
          font: {
            size: 10px;
            font-weight: 400;
          }
        }
      }

      .bottom-right {
        flex-shrink: 0;
        margin-bottom: 2px;
        border-radius: 4px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

$card-radius: 12px;

.card-content {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: var(--el-color-primary-light-9);

  .attr-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 18.75px;
    margin-bottom: 5px;
  }
}

.card-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
  }

  .cashback-desc {
    color: var(--tc-color-primary);
  }
}

.first-card {
  border: 1px solid var(--tc-color-primary);
  border-radius: $card-radius;
  background-color: var(--tc-color-primary);
  overflow: hidden;
  margin-bottom: 10px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100%;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
  }

  h4 {
    line-height: 32px;
    height: 32px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding-left: 12px;
  }
}
</style>
