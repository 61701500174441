<template>
  <div>
    <Empty v-if="isReady && !state.data?.length" desc="" :show-btn="true" @empty-event="fetchExperienceList" />
    <van-skeleton v-else :loading="isLoading" animated :style="{ flexDirection: 'column' }">
      <template #template>
        <div v-for="item in 5" :key="item" flex="~" w="full" m="y-2">
          <van-skeleton-image round />
          <div flex="1" m="l-4">
            <van-skeleton-paragraph row-width="60%" />
            <van-skeleton-paragraph />
            <van-skeleton-paragraph />
            <van-skeleton-paragraph />
          </div>
        </div>
      </template>
      <div p="b-2">
        <div v-if="route.query.query" class="search-result">
          <p m="y-2.5 l-2">
            {{ t('key.attractions.list.search.result', [confirmAttrList[E_RANK_TYPE.EXPERIENCE].length || '0', route.query.query as string]) }}
          </p>

          <Empty v-if="confirmAttrList[E_RANK_TYPE.EXPERIENCE].length === 0" desc="" btn-txt="" />
          <MobileTopExperiencesItem
            v-for="(item, index) of confirmAttrList[E_RANK_TYPE.EXPERIENCE]"
            :key="item.specialTopicSerialNo"
            :top-choice="index === 0"
            :item-index="index"
            :item-data="item"
            :hide-rank-num="true"
            class="m-2 rounded-3 shadow-[0_0_12px_1px_rgba(181,181,181,0.25)]"
            :rank-type="E_RANK_TYPE.EXPERIENCE"
          />
          <h2 mt="6" ml="2" mb="2.5" color="#000">
            {{ t('key.list.other.recommendations') }}
          </h2>
        </div>
        <MobileTopExperiencesItem
          v-for="(item, index) of state.data"
          :key="index"
          class="m-2 rounded-3 shadow-[0_0_12px_1px_rgba(181,181,181,0.25)]"
          :item-index="index"
          :item-data="item"
          :rank-type="E_RANK_TYPE.EXPERIENCE"
        />
      </div>
    </van-skeleton>
  </div>
</template>

<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

const route = useRoute()

const { t } = useI18n()
const { state, isLoading, isReady, fetchExperienceList, fetchSearchExperience, filters } = useExperienceList()
const { confirmAttrList } = useSearchAttr()

const filterConditionsStore = useFilterConditionsStore()
const userInfoStore = useUserStore()

watch(
  [
    () => filterConditionsStore.filterConditions.date,
    () => userInfoStore.userInfo.currency,
    () => filters.value,
  ],
  () => {
    fetchExperienceList()
    fetchSearchExperience()
  },
  {
    immediate: true,
  },
)
</script>

<style lang="scss" scoped>
$card-radius: 12px;

.card-content {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: var(--el-color-primary-light-9);

  .attr-name {
    font-size: 15px;
    font-weight: 500;
    line-height: 18.75px;
    margin-bottom: 5px;
  }
}

.card-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    color: #777474;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
  }

  .cashback-desc {
    color: var(--tc-color-primary);
  }
}

.first-card {
  border: 1px solid var(--tc-color-primary);
  border-radius: $card-radius;
  background-color: var(--tc-color-primary);
  overflow: hidden;
  margin-bottom: 10px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100%;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
  }

  h4 {
    line-height: 32px;
    height: 32px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding-left: 12px;
  }
}
</style>
